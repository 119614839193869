@import '../abstracts/_mixins';
@import '../abstracts/_variables';
@import '../components/title';
@import '../components/button';
@import '../components/input';

/* ******************************** NAVIGATION CART ********************** */
.navigation {
  &__step {
    margin-top: 5rem;

    &__list {
      @include flex-row-justify(space-between);
      counter-reset: count-number;
      margin-bottom: 2rem;

      & li {
        position: relative;
        width: 18%;
        height: 4rem;
        padding: 1rem 2rem;
        text-align: center;
        font-weight: bold;
        border-radius: 1rem;
        color: var(--toc-brown);
        background-color: var(--toc-grey);

        &.step_done {
          background-color: var(--toc-green);

          &::before {
            @include icons($fontAwesomeFree, '\f00c', 1rem, var(--toc-white));
            position: absolute;
            top: -10px;
            left: -10px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 28px;
            height: 28px;
            background-color: var(--toc-green);
            box-shadow: 1px 0px 7px #02620059;
          }
        }

        & a {
          color: var(--toc-white) !important;
        }

        & span {
          &::before {
            counter-increment: count-number;
            content: counter(count-number);
          }
        }

        &.first,
        &.second,
        &.third,
        &.four,
        &.last {
          span {
            &::before {
              margin-right: 1rem;
            }
          }
        }
      }

      &__current {
        color: var(--toc-white) !important;
        background-color: var(--toc-brown) !important;
      }

      /* ******************** RESPONSIVE ******************** */
      @media (max-width: $breakpoint-lg) {
        li {
          padding: 0;
          background: none !important;

          &.first,
          &.second,
          &.third,
          &.four,
          &.last {
            span {
              &::before {
                margin: 0;
              }
            }
          }

          &.step_done {
            &::before {
              display: none;
            }
          }

          &::after {
            content: "";
            display: inline-block;
            position: absolute;
            width: 2rem;
            height: 0.2rem;
            right: 0;
            top: 50%;
            -webkit-transform: translate(2rem, -50%);
            transform: translate(1.5rem, -50%);
            background: var(--toc-dark-grey);
          }

          &:nth-last-child(1)::after {
            display: none;
          }

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            color: var(--toc-dark-grey);
            background: var(--toc-grey);

            b {
              display: none;
            }
          }
        }

        &__current {
          span {
            color: var(--toc-white) !important;
            background: var(--toc-brown) !important;

            &::after {
              display: flex !important;
              position: absolute;
              color: var(--toc-brown);
              margin: 0 !important;
              bottom: -50%;
              width: max-content;
            }

            b {
              display: block !important;
              position: absolute;
              top: 4rem;
              font-size: 1rem;
              color: var(--toc-brown);
            }
          }
        }

        & .step_done {
          background: none;

          span {
            color: var(--toc-white) !important;
            background: var(--toc-green) !important;
          }
        }
      }
    }

    @media (max-width: $breakpoint-lg) {
      margin-top: 2rem;
    }
  }
}